import { useQuery } from '@apollo/client';
import { useAuthInfo } from '@bfa/nextjs-common/contexts';

import {
  GQLLayoutContent,
  GQLMyData,
  GQLProgram,
  GQLQuery,
  GQLUserSubscription,
} from '../../../gql.generated';
import { filterOutInvalidPrograms } from '../../../utils/subscriptionsHelpers';
import GET_NAVBAR from '../graphql/queries/GET_NAVBAR';

interface ExtendedLayout extends GQLLayoutContent {
  giftingNavbarGlobal: GQLLayoutContent['globalVariable'];
  refreshmentsHomeTabFlag: GQLLayoutContent['eligibleForFeature'];
}

interface ExtendedMyData extends GQLMyData {
  layout: ExtendedLayout;
  subscriptions?: Array<GQLUserSubscription>;
}

interface ExtendedGQLQuery extends GQLQuery {
  me: ExtendedMyData;
}

type PropTypes = {
  shouldSkip?: boolean;
};

const useGetNavbar = ({ shouldSkip }: PropTypes = { shouldSkip: false }) => {
  const { isAuthenticated } = useAuthInfo();
  const { data, loading } = useQuery<ExtendedGQLQuery>(GET_NAVBAR, {
    variables: {
      isLoggedIn: isAuthenticated,
    },
    skip: shouldSkip || !isAuthenticated,
  });

  const isGiftingNavbarGlobalOn =
    data?.me?.layout?.giftingNavbarGlobal?.toUpperCase() === 'TRUE';

  const isRefreshmentsHomeTabOn = !!data?.me?.layout?.refreshmentsHomeTabFlag;

  const subscriptions = filterOutInvalidPrograms(data?.me.subscriptions || []);

  const activeSusbscriptions =
    isGiftingNavbarGlobalOn && !!subscriptions.length
      ? subscriptions.filter(
          (subscription) =>
            (subscription?.status === 'ACTIVE' ||
              subscription?.status === 'DO_NOT_RENEW') &&
            subscription.program !== GQLProgram.REFRESHMENTS
        )
      : [];

  const isActiveUserNotCanadian =
    !!activeSusbscriptions?.length &&
    !activeSusbscriptions.some(
      (subscription) => subscription?.shippingAddress?.country === 'CA'
    );

  const itemsCount = data?.me?.commerce?.cart?.itemsCount ?? 0;

  return {
    data: {
      shouldShowGift: isGiftingNavbarGlobalOn && isActiveUserNotCanadian,
      isRefreshmentsHomeTabOn,
      shouldShowShopTeaser: itemsCount > 0,
    },
    loading,
  };
};

export default useGetNavbar;
