import { useQuery } from '@apollo/client';
import { UserType } from '@bfa/common-ui/dist/types/UserType';
import { useAuthInfo } from '@bfa/nextjs-common/contexts';

import {
  GQLLayoutContent,
  GQLProgram,
  GQLQuery,
  GQLSubscriptionStatus,
} from '../../../gql.generated';
import { filterOutInvalidPrograms } from '../../../utils/subscriptionsHelpers';
import GET_NAVBAR from './GET_NAVBAR';

interface ExtendedLayout extends GQLLayoutContent {
  giftingNavbarGlobal: GQLLayoutContent['globalVariable'];
  refreshmentsHomeTabFlag: GQLLayoutContent['eligibleForFeature'];
}

type Props = {
  shouldSkip?: boolean;
  showShopTeaser?: boolean;
  isAuthenticated?: boolean;
};

type NavbarProps = {
  user?: UserType;
  shouldShowRefreshments?: boolean;
  shouldShowHeaderFresh?: boolean;
  shouldShowGift: boolean;
  shouldShowShopTeaser: boolean;
  shouldShowPaymentFailure?: boolean;
  shouldShowMembership?: boolean;
};

const useNavbar = (
  { shouldSkip, isAuthenticated, showShopTeaser }: Props = {
    shouldSkip: false,
    showShopTeaser: true,
    isAuthenticated: false,
  }
): NavbarProps => {
  const { isAuthenticated: hookAuthenticated } = useAuthInfo();
  const { data } = useQuery<GQLQuery>(GET_NAVBAR, {
    variables: {
      isLoggedIn: isAuthenticated || hookAuthenticated,
      showShopTeaser,
    },
    skip: shouldSkip || !(isAuthenticated || hookAuthenticated),
  });

  const itemsCount = data?.me?.commerce?.cart?.itemsCount ?? 0;

  const layout = data?.me?.layout as ExtendedLayout;

  const isGiftingNavbarGlobalOn =
    layout?.giftingNavbarGlobal?.toUpperCase() === 'TRUE';

  const isRefreshmentsHomeTabOn = !!layout?.refreshmentsHomeTabFlag;

  const subscriptions = filterOutInvalidPrograms(data?.me.subscriptions || []);

  const activeSusbscriptions =
    isGiftingNavbarGlobalOn && !!subscriptions.length
      ? subscriptions.filter(
          (subscription) =>
            (subscription?.status === GQLSubscriptionStatus.ACTIVE ||
              subscription?.status === GQLSubscriptionStatus.DO_NOT_RENEW) &&
            subscription.program !== GQLProgram.REFRESHMENTS
        )
      : [];

  const isActiveUserNotCanadian =
    !!activeSusbscriptions?.length &&
    !activeSusbscriptions.some(
      (subscription) => subscription?.shippingAddress?.country === 'CA'
    );

  return {
    shouldShowGift: isGiftingNavbarGlobalOn && isActiveUserNotCanadian,
    user: data?.me.details?.id
      ? ({ id: data.me.details.id } as UserType)
      : undefined,
    shouldShowShopTeaser: itemsCount > 0,
    shouldShowRefreshments:
      data?.me.layout.navigation?.isWithRefreshments &&
      !isRefreshmentsHomeTabOn,
    shouldShowMembership: isRefreshmentsHomeTabOn,
    shouldShowHeaderFresh: data?.me.layout.navigation?.isWithIcons,
    shouldShowPaymentFailure: data?.me.details?.inPaymentFailure,
  };
};

export default useNavbar;
