import { useQuery } from '@apollo/client';

import { GQLQuery } from '../../../../../../gql.generated';
import GET_USER_DATA from '../../queries/GET_USER_DATA';

const useGetUserData = () => {
  return useQuery<GQLQuery>(GET_USER_DATA);
};

export default useGetUserData;
