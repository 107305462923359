import { GQLUserSubscription } from '../../../gql.generated';

export const getSubscriptionFormat = (
  period: GQLUserSubscription['period'],
  program: GQLUserSubscription['program'],
  cadence: GQLUserSubscription['cadence']
) => {
  let subType;
  switch (period) {
    case 1:
      subType = 'Monthly';
      break;
    case 12:
      subType = 'Annual';
      break;
    default:
      subType = 'Unknown';
      break;
  }
  if (cadence === 2) subType = 'Every other month';
  if (program === 'GBX') subType = 'Quarterly';

  return subType;
};
