import gql from 'graphql-tag';

const GET_USER_DATA = gql`
  query getUserData {
    me {
      details {
        id
        username
        email
      }
      subscriptions {
        status
        program
        period
        cadence
        subscriptionItems {
          tentativeNextDeliveryDate
        }
        shippingAddress {
          city
          state
          country
        }
      }
    }
  }
`;

export default GET_USER_DATA;
