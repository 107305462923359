import { Flex, FlexItem } from '@bfa/common-ui';
import { Footer } from '@bfa/common-ui';
import { cookieService } from '@bfa/nextjs-common/services';
import React, {
  ComponentProps,
  ReactElement,
  ReactNode,
  useCallback,
} from 'react';

import { useBusinessId } from '../../contexts/BusinessId';
import { initLoginEvent } from '../../features/Splash/utils/analytics';
import { logAnalyticsEvent } from '../../utils/analytics';
import CookieName from '../../utils/cookies/cookieName';
import FullHeader from '../FullHeader';
import Header from '../Header';
import AdminLoginBanner from './AdminLoginBanner';
import useRegulationCompliance from './useRegulationCompliance';

type LayoutProps = {
  children: ReactNode;
  header?: ComponentProps<typeof Header> | ComponentProps<typeof FullHeader>;
  className?: string;
  fullHeader?: boolean;
  isAuthenticated?: boolean;
  testId?: string;
  footer?: boolean;
};

const Layout = ({
  className,
  children,
  header,
  fullHeader = false,
  isAuthenticated = false,
  testId,
  footer = false,
}: LayoutProps): ReactElement => {
  const businessId = useBusinessId();
  const { shouldDisplayDoNotTrack } = useRegulationCompliance();
  const isAdmin =
    cookieService.getCookie(null, CookieName.IS_ADMIN_LOGIN_AS) === 'true';

  const triggerLoginEvent = useCallback(initLoginEvent(), []);

  const handleClickLogoWrapper = (event?: React.MouseEvent) => {
    if (header?.onLogoClick) {
      logAnalyticsEvent({
        name: 'NAVIGATION_MAIN_MENU',
        label: 'Clicked nav bar IPSY logo',
      });
      header.onLogoClick(event);
    }
  };

  const handleClickLoginWrapper = (event?: React.MouseEvent) => {
    if (header?.onLoginClick) {
      header?.onLoginClick(event);
    }
    triggerLoginEvent({
      type: 'default',
      label: 'Clicked nav bar Login CTA',
    });
  };

  const headerWrapper = {
    ...header,
    onLogoClick: handleClickLogoWrapper,
    onLoginClick: handleClickLoginWrapper,
  };

  return (
    <Flex
      data-testid={testId}
      overflowX="hidden"
      overflowY="auto"
      flexDirection="column"
      minHeight="100vh"
      className={className}
    >
      {fullHeader ? (
        <FullHeader {...headerWrapper} isAuthenticated={isAuthenticated} />
      ) : (
        <Header {...headerWrapper} />
      )}
      {isAdmin && <AdminLoginBanner />}
      <FlexItem className="ux" flex="1" display="flex" flexDirection="column">
        {children}
      </FlexItem>
      {footer && (
        <Footer
          businessId={businessId}
          shouldDisplayDoNotTrack={shouldDisplayDoNotTrack}
        />
      )}
    </Flex>
  );
};

export default Layout;
