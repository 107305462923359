import { buildLogEvent, DetailedAnalyticsEvent } from '@bfa/accounts-core';

const LoginBaseEvent = {
  name: 'LOGIN',
  category: 'Login',
  action: 'Login',
};

type LOGIN_EVENT_LABELS =
  | 'Clicked nav bar Login CTA'
  | 'Clicked Login Cta'
  | 'Login Modal Open'
  | 'Login Modal Close'
  | 'Login Succeeded'
  | 'Login Failed';

export function initLoginEvent() {
  return buildLogEvent<DetailedAnalyticsEvent<LOGIN_EVENT_LABELS>>(
    LoginBaseEvent
  );
}
