import {
  Header as CommonHeader,
  HeaderFresh,
  NextRenderLink,
} from '@bfa/common-ui';
import { ClickEventHandler } from '@bfa/common-ui/dist/lib/utils';
import { ThemeType } from '@bfa/common-ui/dist/types/header';
import { Config } from '@bfa/next-app-config';
import { useClientConfiguration } from '@bfa/nextjs-common/hooks';
import { cookieService } from '@bfa/nextjs-common/services';
import React, { FC, useEffect } from 'react';

import { ABSOLUTE_URLS } from '../../constants';
import CookieName from '../../utils/cookies/cookieName';
import useNavbar from './useNavbar';

export const REFRESHMENTS_TAB = 'refreshments';

type Props = {
  minimum?: boolean;
  cross?: boolean;
  canGoBack?: boolean;
  onCrossClick?: ClickEventHandler;
  onLogoClick?: ClickEventHandler;
  onLoginClick?: ClickEventHandler;
  onBackClick?: ClickEventHandler;
  [key: string]: unknown;
  hideLogo?: boolean;
  showShopTeaser?: boolean;
  activeTab?: string;
  isAuthenticated?: boolean;
  showCart?: boolean;
};

const headerBaseProps = {
  getLogoUrl: () => '/',
  getLoginUrl: () => '/login',
  getJoinUrl: () => '/join-now',
  getShopUrl: () => '/shop',
  getCartUrl: () => '/cart',
  getPointsUrl: () => '/points?refFrom=navigationPointsbutton',
  getAccountUrl: () => '/account',
  getGlambagUrl: () => ABSOLUTE_URLS.GLAM_BAG,
  getMembershipUrl: () => ABSOLUTE_URLS.GLAM_BAG,
  getSubscribeUrl: () => '/subscribe',
  getReactivateUrl: () => '/reactivate',
  getRefreshmentsUrl: () => '/refreshments',
  getBlogUrl: () => '/blog/home',
  getBrandOfferUrl: () => 'https://pages.ipsy.com/brandoffers/',
  getGiftUrl: () => '/gifting',
  cartItemsCount: 0,
  NextRenderLink, // pass in next/link wrapper to ensure in-app routing
};

const getCurrentTheme = (theme: string): ThemeType =>
  ThemeType[ThemeType.HOLIDAYS] === theme
    ? ThemeType.HOLIDAYS
    : ThemeType.DEFAULT;

const FullHeader: FC<Props> = ({
  canGoBack,
  cross,
  minimum,
  onBackClick,
  onCrossClick,
  onLogoClick,
  onLoginClick,
  showShopTeaser = true,
  isAuthenticated = false,
  ...otherProps
}) => {
  const { isInIosApp } = useClientConfiguration();
  const {
    shouldShowShopTeaser,
    shouldShowRefreshments,
    shouldShowHeaderFresh,
    shouldShowPaymentFailure,
    shouldShowGift,
    shouldShowMembership,
    user,
  } = useNavbar({
    shouldSkip: cross || minimum,
    showShopTeaser,
    isAuthenticated,
  });
  const isRefreshmentSelected = otherProps?.activeTab === REFRESHMENTS_TAB;

  useEffect(() => {
    if (isRefreshmentSelected) {
      cookieService.setCookie(null, CookieName.REFRESHMENT_NEW_BADGE, 'false');
    }
  }, [isRefreshmentSelected]);

  const isRefreshmentsNew = () => {
    const newLabelCookie = cookieService.getCookie(
      null,
      CookieName.REFRESHMENT_NEW_BADGE
    );
    return !isRefreshmentSelected && newLabelCookie !== 'false';
  };

  return shouldShowHeaderFresh ? (
    <HeaderFresh
      minimum={minimum}
      cross={cross}
      onLogoClick={onLogoClick}
      onLoginClick={onLoginClick}
      onCrossClick={onCrossClick}
      isNativeIOS={isInIosApp()}
      canGoBack={canGoBack}
      onBackClick={onBackClick}
      refreshmentsShowLabel={isRefreshmentsNew()}
      showShopTeaser={shouldShowShopTeaser}
      splash={false}
      isRefreshmentsEligible={shouldShowRefreshments}
      user={user}
      theme={getCurrentTheme(Config.public.misc.publicNavActualTheme)}
      showGift={shouldShowGift}
      shouldShowPaymentFailure={shouldShowPaymentFailure}
      showMembership={shouldShowMembership}
      {...headerBaseProps}
      {...otherProps}
    />
  ) : (
    <CommonHeader
      minimum={minimum}
      cross={cross}
      onLogoClick={onLogoClick}
      onLoginClick={onLoginClick}
      onCrossClick={onCrossClick}
      isNativeIOS={isInIosApp()}
      canGoBack={canGoBack}
      onBackClick={onBackClick}
      user={user}
      showGift={shouldShowGift}
      showMembership={shouldShowMembership}
      showShopTeaser={shouldShowShopTeaser}
      shouldShowPaymentFailure={shouldShowPaymentFailure}
      {...headerBaseProps}
      {...otherProps}
    />
  );
};

export default FullHeader;
