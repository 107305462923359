import { Column, H3, Row, Text } from '@bfa/common-ui';
import { getMonths } from '@bfa/nextjs-common/utils';
import React from 'react';
import styled from 'styled-components';

import { filterOutInvalidPrograms } from '../../../utils/subscriptionsHelpers';
import useGetUserData from './graphql/hooks/useGetUserData';
import { getSubscriptionFormat } from './util';

const CustomRow = styled(Row)`
  ${Text} {
    margin-top: 0.5rem;

    & span {
      font-family: 'NeueEinstellungBold';
    }
  }
`;

const AdminLoginBanner = () => {
  const request = useGetUserData();

  const details = request?.data?.me?.details;
  const subscriptions = filterOutInvalidPrograms(
    request?.data?.me?.subscriptions || []
  );

  if (!details || !subscriptions || !subscriptions.length) {
    return null;
  }

  return (
    <CustomRow
      backgroundColor="black"
      color="white"
      padding={['2rem 3rem', '3rem 4.5rem', '3rem 2rem']}
    >
      <Column marginTop="1rem" xs={12} md={2}>
        <H3 as="p">Logged In As </H3>
      </Column>
      <Column xs={12} md={10}>
        <Row>
          <Column marginTop="1rem" xs={12} sm={6} lg={4}>
            <Text>
              <span>UserID:</span> {details.id}
            </Text>
            <Text>
              <span>Email:</span> {details.email}
            </Text>
            <Text>
              <span>Username:</span> {details.username}
            </Text>
          </Column>
          {subscriptions?.map((subscription, index) => {
            if (!subscription) return null;
            const subType = getSubscriptionFormat(
              subscription?.period,
              subscription?.program,
              subscription?.cadence
            );

            const month =
              subscription?.subscriptionItems?.[0]?.tentativeNextDeliveryDate
                ?.toString()
                .split('-')[1];

            const year =
              subscription?.subscriptionItems?.[0]?.tentativeNextDeliveryDate
                ?.toString()
                .split('-')[0];

            return (
              <Column key={index} marginTop="1rem" xs={12} sm={6} lg={4}>
                <Text>
                  <span>Sub Type {index + 1}:</span> {subscription?.program} -{' '}
                  {subscription?.status} - {subType}
                </Text>
                <Text>
                  <span>Target Cycle:</span> {getMonths[Number(month) - 1]}{' '}
                  {year}
                </Text>
                <Text>
                  <span>Shipping:</span> {subscription?.shippingAddress?.city},{' '}
                  {subscription?.shippingAddress?.state},{' '}
                  {subscription?.shippingAddress?.country}
                </Text>
              </Column>
            );
          })}
        </Row>
      </Column>
    </CustomRow>
  );
};

export default AdminLoginBanner;
