import {
  Header as CommonHeader,
  HeaderFresh,
  NextRenderLink,
} from '@bfa/common-ui';
import { ClickEventHandler } from '@bfa/common-ui/dist/lib/utils';
import { ThemeType } from '@bfa/common-ui/dist/types/header';
import { Config } from '@bfa/next-app-config';
import { useClientConfiguration } from '@bfa/nextjs-common/hooks';
import { cookieService } from '@bfa/nextjs-common/services';
import React, { FC, useEffect } from 'react';

import { ABSOLUTE_URLS } from '../../constants';
import useGetMenuBrandOffers from '../../hooks/useGetMenuBrandOffers/useGetMenuBrandOffers';
import { logAnalyticsEvent } from '../../utils/analytics';
import CookieName from '../../utils/cookies/cookieName';
import { headerBrandOfferLog } from '../../utils/header';
import useGetNavbar from './hooks/useGetNavbar';

type Props = {
  minimum?: boolean;
  cross?: boolean;
  canGoBack?: boolean;
  onCrossClick?: ClickEventHandler;
  onLogoClick?: ClickEventHandler;
  onLoginClick?: ClickEventHandler;
  onBackClick?: ClickEventHandler;
  user?: {
    id?: string;
    inPaymentFailure?: boolean;
  };
  [key: string]: unknown;
  hideLogo?: boolean;
};

const headerBaseProps = {
  getLogoUrl: () => '/',
  getLoginUrl: () => '/login',
  getJoinUrl: () => '/join-now',
  getShopUrl: () => '/shop',
  getCartUrl: () => '/cart',
  getPointsUrl: () => '/points?refFrom=navigationPointsbutton',
  getAccountUrl: () => '/account',
  getGlambagUrl: () => ABSOLUTE_URLS.GLAM_BAG,
  getMembershipUrl: () => ABSOLUTE_URLS.GLAM_BAG,
  getSubscribeUrl: () => '/subscribe',
  getReactivateUrl: () => '/reactivate',
  getRefreshmentsUrl: () => '/refreshments',
  getBlogUrl: () => '/blog/home',
  getBrandOfferUrl: () => 'https://pages.ipsy.com/brandoffers/',
  getGiftUrl: () => '/gifting',
  cartItemsCount: 0,
  NextRenderLink, // pass in next/link wrapper to ensure in-app routing
};

const getCurrentTheme = (theme: string): ThemeType =>
  ThemeType[ThemeType.HOLIDAYS] === theme
    ? ThemeType.HOLIDAYS
    : ThemeType.DEFAULT;

const Header: FC<Props> = ({
  canGoBack,
  cross,
  minimum,
  onBackClick,
  onCrossClick,
  onLogoClick,
  onLoginClick,
  user,
  ...otherProps
}) => {
  const { isInIosApp } = useClientConfiguration();
  const { data = null, loading } = useGetNavbar({
    shouldSkip: cross || minimum,
  });
  const showBrandOffer = useGetMenuBrandOffers();
  const isRefreshmentSelected = otherProps?.activeTab === 'refreshments';
  useEffect(() => {
    if (isRefreshmentSelected) {
      cookieService.setCookie(null, CookieName.REFRESHMENT_NEW_BADGE, 'false');
    }
  }, [isRefreshmentSelected]);

  const isRefreshmentsNew = () => {
    const newLabelCookie = cookieService.getCookie(
      null,
      CookieName.REFRESHMENT_NEW_BADGE
    );
    return newLabelCookie !== 'false';
  };
  const shouldShowShopTeaser = data?.shouldShowShopTeaser;
  const shouldShowRefreshments =
    (otherProps?.isWithRefreshments as boolean) &&
    !data?.isRefreshmentsHomeTabOn;
  const shouldShowMembership = data?.isRefreshmentsHomeTabOn;
  const shouldShowHeaderFresh = otherProps?.isWithIcons;
  const userData = user?.id ? { id: user.id, ...user } : undefined;

  const logBrandOfferClick = () => {
    logAnalyticsEvent({
      name: 'NAVIGATION_MAIN_MENU',
      label: headerBrandOfferLog.label,
    });
  };

  if (loading) return null;

  return shouldShowHeaderFresh ? (
    <HeaderFresh
      minimum={minimum}
      cross={cross}
      onLogoClick={onLogoClick}
      onLoginClick={onLoginClick}
      onCrossClick={onCrossClick}
      isNativeIOS={isInIosApp()}
      canGoBack={canGoBack}
      onBackClick={onBackClick}
      refreshmentsShowLabel={isRefreshmentsNew()}
      showShopTeaser={shouldShowShopTeaser}
      splash={false}
      isRefreshmentsEligible={shouldShowRefreshments}
      user={userData}
      theme={getCurrentTheme(Config.public.misc.publicNavActualTheme)}
      showGift={data?.shouldShowGift}
      showMembership={shouldShowMembership}
      showBrandOffers={showBrandOffer}
      onBrandOfferClick={logBrandOfferClick}
      {...headerBaseProps}
      {...otherProps}
    />
  ) : (
    <CommonHeader
      minimum={minimum}
      cross={cross}
      onLogoClick={onLogoClick}
      onLoginClick={onLoginClick}
      onCrossClick={onCrossClick}
      isNativeIOS={isInIosApp()}
      canGoBack={canGoBack}
      onBackClick={onBackClick}
      user={userData}
      showGift={data?.shouldShowGift}
      showMembership={shouldShowMembership}
      showShopTeaser={shouldShowShopTeaser}
      showBrandOffers={showBrandOffer}
      onBrandOfferClick={logBrandOfferClick}
      {...headerBaseProps}
      {...otherProps}
    />
  );
};

export default Header;
