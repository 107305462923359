import { GQLProgram, GQLUserSubscription, Maybe } from '../gql.generated';

const INVALID_PROGRAMS = [GQLProgram.CREATOR_SEED];

export const filterOutInvalidPrograms = (
  subscriptions: Maybe<GQLUserSubscription>[]
): GQLUserSubscription[] => {
  const presentSubscriptions = subscriptions
    .filter((it) => it !== null)
    .map((it) => it as GQLUserSubscription);

  return presentSubscriptions.filter(
    (it) => !it.program || !INVALID_PROGRAMS.includes(it.program)
  );
};
