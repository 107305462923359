import { useQuery } from '@apollo/client';
import { logError } from '@bfa/nextjs-common/utils';

import { useBusinessId } from '../../contexts/BusinessId';
import GET_GVS_VAR from '../../graphql/queries/GET_GVS_VAR';

const useGetMenuBrandOffers = () => {
  const businessId = useBusinessId();
  try {
    const result = useQuery<any>(GET_GVS_VAR, {
      variables: {
        key: 'PUBLIC_ENABLE_HAMBURGER_MENU_BRAND_OFFERS',
      },
      context: { businessId },
    });
    return result.data?.me?.layout?.gvsVar?.toUpperCase() === 'TRUE';
  } catch (e) {
    logError(
      '[getGvsVar] Error fetching gvs variable PUBLIC_ENABLE_HAMBURGER_MENU_BRAND_OFFERS',
      e
    );
    return false;
  }
};

export default useGetMenuBrandOffers;
