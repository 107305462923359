import { gql } from 'graphql-tag';

const GET_REGULATION_COMPLIANCE = gql`
  query getRegulationCompliance($isLoggedIn: Boolean!) {
    me {
      id
      layout {
        regulationComplianceFlag: eligibleForFeature(
          globalKey: "PUBLIC_REGULATION_COMPLIANCE_ENABLE"
        )
      }
      subscriptions @include(if: $isLoggedIn) {
        id
        shippingAddress {
          id
          state
        }
      }
    }
  }
`;

export default GET_REGULATION_COMPLIANCE;
