import gql from 'graphql-tag';

const GET_NAVBAR = gql`
  query getNavbar($isLoggedIn: Boolean!, $showShopTeaser: Boolean!) {
    me {
      id
      details {
        id
        inPaymentFailure
      }
      subscriptions @include(if: $isLoggedIn) {
        id
        status
        program
        shippingAddress {
          id
          country
        }
      }
      commerce @include(if: $showShopTeaser) {
        cart {
          itemsCount
        }
      }
      layout {
        giftingNavbarGlobal: globalVariable(key: "PUBLIC_GIFTING_NAV_BAR")
        refreshmentsHomeTabFlag: eligibleForFeature(
          globalKey: "PUBLIC_REFRESHMENTS_HOME_TAB_ENABLED"
        )
        navigation {
          isWithRefreshments
          isWithIcons
        }
      }
    }
  }
`;

export default GET_NAVBAR;
