import { useQuery } from '@apollo/client';
import { useAuthInfo } from '@bfa/nextjs-common/contexts';

import { useBusinessId } from '../../../contexts/BusinessId';
import { GQLLayoutContent, GQLQuery } from '../../../gql.generated';
import GET_REGULATION_COMPLIANCE from './GET_REGULATION_COMPLIANCE';

type ExtendedLayout = {
  regulationComplianceFlag: GQLLayoutContent['vcmsAsset'];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useRegulationCompliance = () => {
  const businessId = useBusinessId();
  const { isAuthenticated } = useAuthInfo();
  const { data } = useQuery<GQLQuery>(GET_REGULATION_COMPLIANCE, {
    variables: {
      isLoggedIn: isAuthenticated,
    },
    context: { teamName: 'BFA Acquisition', businessId },
  });

  const regulationComplianceFlag = ((data?.me?.layout || {}) as ExtendedLayout)
    ?.regulationComplianceFlag;

  const subscriptions = data?.me?.subscriptions;

  const hasAnyAddressFromCalifornia = subscriptions?.some(
    (subscription) => subscription?.shippingAddress?.state === 'CA'
  );

  const shouldDisplayDoNotTrack =
    regulationComplianceFlag && hasAnyAddressFromCalifornia;

  return {
    shouldDisplayDoNotTrack,
  };
};

export default useRegulationCompliance;
